import UIkit from '@node_modules/uikit';
import Icons from '@node_modules/uikit/dist/js/uikit-icons';



// loads the Icon plugin
UIkit.use(Icons);


// components can be called from the imported UIkit reference
// UIkit.notification('Hello world.');

// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
	// Private Functions
// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

/**
 * Simulate a click event.
 * @private (helper Function)
 * @param {Element} elem  the element to simulate a click on
 */

/**
 * Simulate a click event.
 * @public
 * @param {Element} elem  the element to simulate a click on
 */
function simulateClick(elem) {
	// Create our event (with options)
	var evt = new MouseEvent('click', {
		bubbles: true,
		cancelable: true,
		view: window
	});
	// If cancelled, don't dispatch our event
	var canceled = !elem.dispatchEvent(evt);
	//alert('test');
};


// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
	// Document ready
// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

document.addEventListener('DOMContentLoaded', function() { 'use strict';


	/**
	 * Menu toggle Accordion
	*/

	(function(){

		var 	mainNavToggleAccordionItems = document.querySelectorAll('#mainNav .cc-toggle-accordion'),
				accordionItems = document.querySelectorAll('#mainFooterAccordion > li'),
				itemHref = '';

		UIkit.scroll('.cc-toggle-accordion');

		[].forEach.call(mainNavToggleAccordionItems, function(el) {
			el.addEventListener('click', function() {

				itemHref = this.getAttribute('href');

				for ( var i = 0; i < accordionItems.length; i++) {

					if ( itemHref.indexOf(accordionItems[i].id) !== -1 ) {
						UIkit.accordion('#mainFooterAccordion').toggle(i);
					}
				}//next i
			})
		});
		
	})();

	/**
	 * Menu show Switcher-Item
	*/

	(function(){

		var 	mainNavShowSwitcherItems = document.querySelectorAll('#mainNav .cc-show-switcher-item'),
				servicNavItems = document.querySelectorAll('.cc-service-iconnav-item > a'),
				clickedIndex = '';

		[].forEach.call(mainNavShowSwitcherItems, function(el) {
			el.addEventListener('click', function(event) {

				event.preventDefault();

				clickedIndex = Array.prototype.indexOf.call(mainNavShowSwitcherItems, this);

				UIkit.scroll('.cc-show-switcher-item').scrollTo('#mainContent');
				
				simulateClick(servicNavItems[clickedIndex]);

			});
		});


	})();

});